<template>
  <el-container class="home-container">
    <el-aside class="el-aside rr-sidebar hidden-xs-only" style="height: 100%;background-color: #545c64">
        <Header :navActive='navActive'/>
    </el-aside>
    <el-container class="con-right">
      <el-main>
      <Breadcrumb/>

<el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>列表</el-breadcrumb-item>
</el-breadcrumb>

<!-- 内容 开始 -->
<el-card class="box-card mt20">
  首页
  <div style="font-size: 20px;">
    <!-- SVG icon 自身不带任何属性，你需要额外提供属性。-->
    <edit style="width: 1em; height: 1em; margin-right: 8px;" />
    <share style="width: 1em; height: 1em; margin-right: 8px;" />
    <delete style="width: 1em; height: 1em; margin-right: 8px;" />
    <search style="width: 1em; height: 1em; margin-right: 8px;" />
  </div>
</el-card>
<!-- 内容 结束 -->

    </el-main>
    </el-container>
</el-container>
</template>

<script>
import { onMounted } from 'vue'
import Header from 'components/common/header/Header'
import Breadcrumb from 'components/common/breadcrumb/Breadcrumb'

export default {
  name: 'Home',
  components: {
    Header,
    Breadcrumb
  },
  setup () {
    onMounted(() => {
    })
    return {
      navActive: '1'
    }
  }
}
</script>
